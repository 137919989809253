import axios from "axios";
import * as types from "../types/type";

const INFO_URL = "https://api-stage.el3mentapp.com/tracks/info/send";
const DELETE_URL = "https://api-stage.el3mentapp.com/tracks/delete"

export const collabInfo = (token) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get(INFO_URL,{ headers: { "content-type": "application/json","Authorization": token}})
            .then((response) => {
                if (response) {
                    dispatch({ type: types.REGISTER_SUCCESS, payload: response });
                    return resolve(response);
                } else {
                    dispatch({ type: types.REGISTER_ERROR });
                    return resolve({ message: "Error" });
                }
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const deleteCollab = (token) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(DELETE_URL,{ headers: { "content-type": "application/json","Authorization": token}})
            .then((response) => {
                if (response) {
                    dispatch({ type: types.REGISTER_SUCCESS, payload: response });
                    return resolve(response);
                } else {
                    dispatch({ type: types.REGISTER_ERROR });
                    return resolve({ message: "Error" });
                }
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
