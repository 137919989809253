import { REGISTER_SUCCESS, REGISTER_ERROR } from "../types/type"

const initialState = {
    registerdata: [],
    error: ''

}


const registerReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (action.type) {
        case REGISTER_SUCCESS:
            {
                return {
                    ...state,
                    ...payload,
                    registerdata: action.payload,
                }
            }
        case REGISTER_ERROR: {
            return {
                ...state,
                error: action.error,
            }
        }

        default:
            return state
    }
}

export default registerReducer
