import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import registerReducer from '../reducer/register'

export default function AppStore() {
    const rootReducer = combineReducers({
        registerReducer
    });

    const store = createStore(
        rootReducer,
        compose(
            applyMiddleware(thunk),
            window.devToolsExtension ? window.devToolsExtension() : (f) => f
        )
    );
    return store;
}