import "./App.css";
import Collab from './component/Collab';
import { BrowserRouter as Router, Switch, Route, Redirect, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/:id" render={(props) => <Collab {...props} />}/>       
        </Switch>
      </Router>
    </div>
  );
}

export default App;