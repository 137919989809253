import React,{ useLayoutEffect, useState,useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, withRouter, useParams, Switch } from 'react-router-dom';
import UserImg from "../common/images/user-img.png";
import logoImg from "../common/images/logoImg.png";
import logo from "../common/images/logo.png";
import infoAudio from "../common/images/infoAudio.png";
import artist1 from "../common/images/artist-1.png";
import artist2 from "../common/images/artist-2.png";
import artist3 from "../common/images/artist-3.png";
import icoApple from "../common/images/icoApple.png";
import icoAndroid from "../common/images/icoAndroid.png";
import SampleMusic from "../common/images/sample.mp3";
import userProfile from "../common/images/userProfile.png";
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { collabInfo,deleteCollab} from '../actions';

const Collab = (props) => {
  const id = props.match.params.id;

  const dispatch = useDispatch()
  const [removeVal, setRemoveVal] = useState("Remove")
  const [audioStatus, changeAudioStatus] = useState(false);
  const [artistName, setArtistName] = useState("An artist");
  const [trackName, setTrackName] = useState("The Track");
  const [collabRoles, setCollabRoles] = useState("Vocalist, Piano, Guitar");
  const [collabName, setCollabName] = useState("Your Name");
  const [collabCode, setCollabCode] = useState("1234");
  const [artistImage, setArtistImage] = useState("logoImg");
  const [coverUrl, setCoverURL] = useState("logoImg");
  const [audioSample, setAudioSample] = useState("SampleMusic");
  const [token, setToken] = useState(id);
  const e = useRef();

  useEffect(() =>{
    fetchCollabInfo();
  },[]);

  const fetchCollabInfo = (async () => {
    try{
    const collabstuff = await dispatch(
      collabInfo(token),
    )
    console.log(collabstuff);
    localStorage.setItem("infoStore", JSON.stringify(collabstuff?.data?.data?.attributes));
    let info = JSON.parse(localStorage.getItem("infoStore"));
    if(info.me.deleted == true){
      setRemoveVal("Removed");
      console.log("Here1")

    }
    setArtistName(info.artistName);
    setCollabName(info.me.name);
    setCollabRoles(info.me.roles.join(", "));
    setCollabCode(info.me.code);
    setTrackName(info.trackName);
    setArtistImage(info.artistAvatarUrl);
    setCoverURL(null? infoAudio: info.coverUrl);
    setAudioSample(info.trackUrl);
    }
    catch(error){
        setRemoveVal("Removed");
        console.log("Here2")
    }
  });

  const removeTag = async ()  => {
    if(removeVal == "Remove"){
      const delete1 = await dispatch(
        deleteCollab(token), setRemoveVal("Removing")
      )
      setRemoveVal("Removed");
    }

  }

  let audio = new Audio(audioSample);

  const startAudio = () => {
    audio.play();
    changeAudioStatus(true);
  };

  const pauseAudio = () => {
    audio.pause();
    changeAudioStatus(false);
  };

  function Child() {
    let { id } = useParams();
    setToken(id);
    console.log(id);
  }

  return (
    <>
        <main class="infoDtlPge collab">
        <div class="mapImgwrapper">
            <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12">
                    <div class="logoWrap">
                      <a href="https://www.el3mentapp.com/">el3ment.</a>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                      <div class="userInfoImg">
                        <img src={artistImage} class="img-fluid"/>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infoDtlWrap">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-sm-12">
                        <div class="infoDtlData">
                            <div class="userInfoName">
                                <h2>{artistName} tagged you as a collaborator!</h2>
                                <p>You can choose to remove the tag below or join el3ment and connect with {artistName}. <a href="https://www.el3mentapp.com/">More about el3ment.</a></p>
                            </div>
                        </div>
                        <div class="infoMdlDtl">
                            <div class="row justify-content-center">
                              <div class="col-md-8 col-sm-12">
                                  <div class="row">
                                      <div class="col-sm-4 col-md-3">
                                          <div class="palyVideoWrap">
                                              <div class="imgWrap">
                                                <img src={coverUrl} class="img-fluid"/>
                                                <div class="videoLink">
                                                {audioStatus ? (
                                                     <a id="play-pause-button" class="fa fa-pause" onClick={() => pauseAudio()}></a>
                                                    ) : (
                                                      <a id="play-pause-button" class="fa fa-play" onClick={() => startAudio()}></a>
                                                    )}
                                                </div>
                                              </div>
                                              <div class="dataWrap">
                                                  <p>{trackName}<br/>
                                                    <span>20s Sample</span></p>
                                                    <p><span>Collab ID: {id}</span></p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-sm-8 col-md-9">
                                          <ul class="audioListing">
                                              <li class="active">
                                                  <div class="leftPanel">
                                                      <a href="https://www.el3mentapp.com/">
                                                        <div class="imgWrap">
                                                            <img src={userProfile} class="img-fluid"/>
                                                        </div>
                                                        <div class="dataWrap">
                                                          <h2>{collabName}</h2>
                                                          <p>{collabRoles}</p>
                                                        </div>
                                                      </a>
                                                  </div>
                                                  <div class="rightPanel">
                                                    <button class="btn btnRemove" onClick={() => removeTag()}>{removeVal}</button>
                                                  </div>
                                              </li>
                                              {/*}
                                              <li>
                                                  <div class="leftPanel">
                                                    <a href="#">
                                                      <div class="imgWrap">
                                                          <img src={artist2} class="img-fluid"/>
                                                      </div>
                                                      <div class="dataWrap">
                                                          <h2>Artist Name</h2>
                                                          <p>Vocals</p>
                                                      </div>
                                                    </a>
                                                  </div>
                                                 
                                              </li>
                                              <li>
                                                  <div class="leftPanel">
                                                    <a href="#">
                                                      <div class="imgWrap">
                                                          <img src={artist3} class="img-fluid"/>
                                                      </div>
                                                      <div class="dataWrap">
                                                          <h2>Artist Name</h2>
                                                          <p>Guitarist</p>
                                                      </div>
                                                    </a>
                                                  </div>
                                                  
                                                    </li> */}
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footerWrap">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="infoBtmDtl">
                    <div class="leftDataWrap">
                        <div class="imgWrap">
                            <a href="https://www.el3mentapp.com/">
                              <img src={logoImg} class="img-fluid"/>
                            </a>
                        </div>
                        <div class="dataWrap">
                            <p><span><strong>Join the el3ment platform</strong></span>  using <br/> your personalized invite code!</p>
                        </div>
                    </div>
                    <div class="rightDataWrap">
                        <div class="inviteCodeWrap">
                            <p>INVITE CODE</p>
                            <h2>{collabCode}</h2>
                        </div>
                        <div class="ctaWrap">
                            <button onClick={(e) => { e.preventDefault(); window.location.href='https://apps.apple.com/us/app/el3ment/id1575590744';}} class="btn"><img src={icoApple} class="img-fluid"/> APP FOR iOS</button>
                            {/*<button class="btn"><img src={icoAndroid} class="img-fluid"/> APP FOR ANDROID</button> */}
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
        </main>  
</>
  );
}

export default withRouter(Collab);